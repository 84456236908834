import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { getTokenFromAuth0 } from '../util/util';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // Clone the request to modify it
    let clonedRequest = req.clone();

    // Check if the request contains the request-id header and remove it
    if (clonedRequest.headers.has('request-id')) {
      clonedRequest = clonedRequest.clone({
        headers: clonedRequest.headers.delete('request-id'),
      });
    }

    if (getTokenFromAuth0() != null) {
      const token = getTokenFromAuth0();
      if (!clonedRequest.url.includes('$web')) {
        clonedRequest = clonedRequest.clone({
          headers: clonedRequest.headers.append('Authorization', `Bearer ${token.body.access_token}`),
        });
      }
    }
    return next.handle(clonedRequest);
  }
}
