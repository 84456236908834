import { Component } from '@angular/core';
import { LocalisationService } from './services/localisation.service';
import { ConfigService } from './services/config.service';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(translate: LocalisationService,private config: ConfigService) {
     translate.setLanguage();
     if(environment.name !== 'local'){
     this.config.getApiUrl().subscribe((uri)=>{
       environment.apiRoot = uri.apiBaseUrl;
       environment.publicBlobBaseUrl =  uri.blobURL + '$web/';
       environment.sasToken =  uri.sasToken ;
    })
  }
  }
}
