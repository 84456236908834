import { ApplicationRef, DoBootstrap, Injector, NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SiLandingPageModule, SiLoadingSpinnerModule, SimplElementNgModule} from '@simpl/element-ng';
import { AppComponent } from './app.component';
import { LandingComponent } from './pages/landing/landing.component';
import { AuthClientConfig, AuthGuard, AuthModule } from '@auth0/auth0-angular';
import { setAppInjector } from './util/app-injector';
import { getAuth0config } from './util/util';
import { AuthHttpInterceptor } from './services/AuthHttpInterceptor';
import {  provideState, provideStore, StoreModule } from '@ngrx/store';
import { appReducer } from './store/productgroup.reducer';
import { environment } from '../environments/environment';
import { ModalModule } from 'ngx-bootstrap/modal';


// Define the routes
const routes: Routes = [
  { path: '', component: LandingComponent, pathMatch: 'full' }, 
  {
    path: 'terms',
    loadChildren: () => import('./pages/shared/terms/terms-and-conditions-routing.module')
    .then(m => m.TermsAndConditionsRoutingModule)
  },  
  {
    path: 'main',
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    canLoad: [AuthGuard],
    loadChildren: () => import('./pages/main/main.module').then(m => m.MainModule)
  },
  { path: 'page-not-found', 
    loadChildren: () => import('./pages/shared/page-not-found/page-not-found.module')
      .then(m => m.PageNotFoundModule)
  },
  { path: '**', 
    loadChildren: () => import('./pages/shared/page-not-found/page-not-found.module')
      .then(m => m.PageNotFoundModule)
  }
];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({ declarations: [
        AppComponent,
        LandingComponent
    ],
    exports: [RouterModule],
    bootstrap: [AppComponent], imports: [BrowserAnimationsModule,
        SimplElementNgModule,
        //auth0 config
        AuthModule.forRoot(getAuth0config()),
        RouterModule.forRoot(routes, {
            useHash: false,
            enableTracing: false
        }),
        SiLandingPageModule,
        SiLoadingSpinnerModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient],
            }
        }),
        ModalModule.forChild(),
        StoreModule.forRoot({}, {})], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthHttpInterceptor,
            multi: true
        },
        provideStore(),
        provideState({ name: 'productdetails', reducer: appReducer }),
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule implements DoBootstrap {
  constructor(injector: Injector, private authConfig: AuthClientConfig) {
    setAppInjector(injector);
  }

  // Bootstrap the app
  ngDoBootstrap(appRef: ApplicationRef): void {
    if (environment.production) {
      this.authConfig.set(getAuth0config());
    }
    appRef.bootstrap(AppComponent);
  }
  

}
