import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
// import { environment } from 'src/environments/environment';
// import { of } from 'rxjs';
import PossibleReturnType from '../util/util';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor(private http: HttpClient) { }
  getApiUrl() {
      return this.http.get<PossibleReturnType>('assets/apiurl.json', { responseType: 'json' }); // fetch dynamic dev api url from build
  }
}
