import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { HyperLinkInfo } from "../model/HyperLinkInfo";
import { map, Observable } from "rxjs";
import PossibleReturnType from "../util/util";

@Injectable({
  providedIn: 'root',
})

export class FooterLinksService {

  constructor(private httpClient: HttpClient) { }

  public getLandingFooterLink() {
    const data = this.httpClient.get<HyperLinkInfo[]>(environment.publicBlobBaseUrl + 'footers/landing-page.json?'+ environment.sasToken);
    return this.getHyperLinkDetails(data, 'SoftwareManager');
  }

  public getHyperLinkDetails(data: Observable<HyperLinkInfo[]>, productType: string) {
    // NOSONAR
    /* eslint-disable */
    return data.pipe(
      map((x) =>
        x.filter((y) => {
          if (y.productType === null || y.productType === undefined) {
            return y;
          } else if (y.productType !== null && y.productType === productType) {
            return y;
          } else {
            return null;
          }
        })
      ),
      map((x) => [...this.sortLinks(x)])
    );
  }

  sortLinks(x: PossibleReturnType) {
    return x.sort((a: { order: number; }, b: { order: number; }) => a.order - b.order)
  }

  public getHtmlDocument(documentPath: string) {
    const urlWithSas = `${documentPath}?${environment.sasToken}`;
    return this.httpClient.get(urlWithSas, { responseType: 'text' });
  }

}