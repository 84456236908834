
import { createReducer, on } from '@ngrx/store';
import { setProductGroup } from './productgroup.action';
import { ProductGroup } from '../model/productdetails';

const localstorage = JSON.parse(localStorage.getItem('productGroupData') || 'null');

export const initialState: ProductGroup = {
  productLine: localstorage ? localstorage.productLine : '',
  productName: localstorage ? localstorage.productName : '',
  availableRatePlanCount: localstorage ? localstorage.availableRatePlanCount : 0,
  assignedRatePlanCount: localstorage ? localstorage.assignedRatePlanCount : 0,
  staleTokenCount: localstorage ? localstorage.staleTokenCount : 0,
};

 export const appReducer = createReducer(
  initialState,
  on(setProductGroup, (state, { productdetails }) => {
    localStorage.setItem('productGroupData', JSON.stringify(productdetails)); // Save to local storage
    return { ...state,
      productLine: productdetails.productLine,
      productName: productdetails.productName,
      assignedRatePlanCount: productdetails.assignedRatePlanCount,
      availableRatePlanCount: productdetails.availableRatePlanCount,
      staleTokenCount: productdetails.staleTokenCount,
 };
  })
);



