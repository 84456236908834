<si-landing-page
  [heading]="'Software Manager'"
  [subtitle]="''"
  [links]="footerLinks"
  backgroundImageUrl="/assets/images/Loginpage-Image.svg"
  [copyrightDetails]="copyrightDetails"
  [version]="version"
>
  <button type="submit" class="btn btn-primary inline"  (click)="loginWithRedirect()" >
   Log in
  </button>
  <div *ngIf="loading" class="spinner-wrapper">
    <si-loading-spinner></si-loading-spinner>
  </div>
</si-landing-page>
