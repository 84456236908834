import { AuthConfig } from "@auth0/auth0-angular";
import { environment } from "../../environments/environment";
import { Constants } from "./constants";


export const EmailRegexExp = /^[a-zA-Z0-9.-_]+@[a-zA-Z.-]+[.]{1}[a-zA-Z]{2,}/;
export const ProductInstallationIdRegExp = /^SGP-(?:\{?(?:[0-9a-fA-F]){8}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){4}-(?:[0-9a-fA-F]){12}\}?)$/;
export function isNullOrUndefined(obj: string | null | undefined): boolean {
  return typeof obj === "undefined" || obj === null;
}

const idpDisplayOff = 'apple-off twitter-off github-off facebook-off' as PossibleReturnType;

// Get the new token from Auth0
export function getNewTokenFromAuth0() {
const localStoredToken = JSON.parse(
  localStorage[ Constants.TOKEN_KEY ]
);
localStoredToken.expiresAt = 0;
localStorage[ Constants.TOKEN_KEY] = JSON.stringify(localStoredToken);
}

export function getTokenFromAuth0() {
  let localStoredToken = null;
  const data = localStorage[Constants.TOKEN_KEY];
  if(data !== undefined){
   localStoredToken = JSON.parse(
    localStorage[Constants.TOKEN_KEY]
  );
   return localStoredToken;
}
  }

  
// Auth0 configuration
export function getAuth0config(): AuthConfig {
  return {
    useRefreshTokens: true,
    domain: environment.siemensId.domain,
    clientId: environment.siemensId.clientId,
    authorizationParams: {
      connection: 'main-tenant-oidc',
      audience: environment.siemensId.audience,
      scope: 'openid profile email offline_access',
      display: idpDisplayOff
    },
    cacheLocation: 'localstorage',
    httpInterceptor: {
      allowedList: [
       environment.apiRoot + '*'
      ]
    }
  };
}

export function resizeTable(ngxtable: PossibleReturnType) {
  setTimeout(() => ngxtable?.recalculate());
}

type PossibleReturnType = string | number | any; //NOSONAR
export default PossibleReturnType;
